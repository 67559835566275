const Layout = () => import('@/layout/index.vue')

export default [
    {
        path: '/admin/permisson',
        component: Layout,
        redirect: '/admin/permisson/menu',
        name: 'admin',
        meta: {
            title: '权限管理',
            icon: 'el-icon-lock',
            auth: ['func.admin']
        },
        children: [
            {
                path: 'role',
                name: 'admin_role',
                redirect: '/admin/permisson/role',
                meta: {
                    title: '角色管理',
                    auth: ['func.admin_role']
                },
                children: [
                    {
                        path: '',
                        name: 'admin_role_list',
                        component: () => import('@/views/admin/role/list.vue'),
                        meta: {
                            title: '角色管理',
                            sidebar: false,
                            breadcrumb: false,
                            activeMenu: '/admin/permisson/role',
                            cache: ['admin_role_edit'],
                            auth: ['func.admin_role.list']
                        }
                    },
                    {
                        path: 'detail',
                        name: 'admin_role_create',
                        component: () => import('@/views/admin/role/detail.vue'),
                        meta: {
                            title: '新增角色',
                            sidebar: false,
                            activeMenu: '/admin/permisson/role',
                            cache: true,
                            noCache: 'admin_role_list',
                            auth: ['func.admin_role.create']
                        }
                    },
                    {
                        path: 'detail/:id',
                        name: 'admin_role_edit',
                        component: () => import('@/views/admin/role/detail.vue'),
                        meta: {
                            title: '编辑角色',
                            sidebar: false,
                            activeMenu: '/admin/permisson/role',
                            cache: true,
                            noCache: 'admin_role_list',
                            auth: ['func.admin_role.edit']
                        }
                    }
                ]
            },
            {
                path: 'menu',
                name: 'admin_menu',
                redirect: '/admin/permisson/menu',
                meta: {
                    title: '菜单管理',
                    auth: ['func.admin_menu']
                },
                children: [
                    {
                        path: '',
                        name: 'admin_menu_list',
                        component: () => import('@/views/admin/menu/list.vue'),
                        meta: {
                            title: '菜单管理',
                            sidebar: false,
                            breadcrumb: false,
                            activeMenu: '/admin/permisson/menu',
                            cache: ['admin_menu_edit'],
                            auth: ['func.admin_menu.list']
                        }
                    },
                    {
                        path: 'detail',
                        name: 'admin_menu_create',
                        component: () => import('@/views/admin/menu/detail.vue'),
                        meta: {
                            title: '新增菜单',
                            sidebar: false,
                            activeMenu: '/admin/permisson/menu',
                            cache: true,
                            noCache: 'admin_menu_list',
                            auth: ['func.admin_menu.create']
                        }
                    },
                    {
                        path: 'detail/:id',
                        name: 'admin_menu_edit',
                        component: () => import('@/views/admin/menu/detail.vue'),
                        meta: {
                            title: '编辑菜单',
                            sidebar: false,
                            activeMenu: '/admin/permisson/menu',
                            cache: true,
                            noCache: 'admin_menu_list',
                            auth: ['func.admin_menu.edit']
                        }
                    }
                ]
            }
        ]
    },
    {
        path: '/admin/base_settings',
        component: Layout,
        redirect: '/admin/base_settings/user',
        name: 'admin2',
        meta: {
            title: '基本设置',
            icon: 'el-icon-setting',
            auth: ['func.base_settings']
        },
        children: [
            {
                path: 'user',
                name: 'admin_user',
                redirect: '/admin/base_settings/user',
                meta: {
                    title: '用户管理',
                    auth: ['func.admin_user']
                },
                children: [
                    {
                        path: '',
                        name: 'admin_user_list',
                        component: () => import('@/views/admin/user/list.vue'),
                        meta: {
                            title: '用户管理',
                            sidebar: false,
                            breadcrumb: false,
                            activeMenu: '/admin/base_settings/user',
                            cache: ['admin_user_edit'],
                            auth: ['func.admin_user.list']
                        }
                    },
                    {
                        path: 'detail',
                        name: 'admin_user_create',
                        component: () => import('@/views/admin/user/detail.vue'),
                        meta: {
                            title: '新增用户',
                            sidebar: false,
                            activeMenu: '/admin/base_settings/user',
                            cache: true,
                            noCache: 'admin_user_list',
                            auth: ['func.admin_user.create']
                        }
                    },
                    {
                        path: 'detail/:id',
                        name: 'admin_user_edit',
                        component: () => import('@/views/admin/user/detail.vue'),
                        meta: {
                            title: '编辑用户',
                            sidebar: false,
                            activeMenu: '/admin/base_settings/user',
                            cache: true,
                            noCache: 'admin_user_list',
                            auth: ['func.admin_user.edit']
                        }
                    }
                ]
            },
            {
                path: 'recharge',
                name: 'admin_recharge',
                redirect: '/admin/base_settings/recharge',
                meta: {
                    title: '费用充值',
                    auth: ['func.admin_recharge']
                },
                children: [
                    {
                        path: '',
                        name: 'admin_recharge_list',
                        component: () => import('@/views/admin/recharge/list.vue'),
                        meta: {
                            title: '费用充值',
                            sidebar: false,
                            breadcrumb: false,
                            activeMenu: '/admin/base_settings/recharge',
                            cache: ['admin_recharge_edit'],
                            auth: ['func.admin_recharge.list']
                        }
                    },
                    {
                        path: 'recharge_log/:id',
                        name: 'admin_recharge_recharge_log',
                        component: () => import('@/views/admin/recharge/recharge_log.vue'),
                        meta: {
                            title: '充值日志',
                            sidebar: false,
                            breadcrumb: false,
                            activeMenu: '/admin/base_settings/recharge',
                            noCache: 'admin_recharge_list',
                            auth: ['func.admin_recharge.recharge_log']
                        }
                    },
                    // {
                    //     path: 'detail',
                    //     name: 'admin_recharge_create',
                    //     component: () => import('@/views/admin/recharge/detail.vue'),
                    //     meta: {
                    //         title: '新增用户',
                    //         sidebar: false,
                    //         activeMenu: '/admin/base_settings/user',
                    //         cache: true,
                    //         noCache: 'admin_user_list',
                    //         auth: ['func.admin_user.create']
                    //     }
                    // },
                    {
                        path: 'recharge/:id',
                        name: 'admin_recharge_recharge',
                        component: () => import('@/views/admin/recharge/detail.vue'),
                        meta: {
                            title: '充值',
                            sidebar: false,
                            activeMenu: '/admin/base_settings/recharge',
                            cache: true,
                            noCache: 'admin_recharge_list',
                            auth: ['func.admin_recharge.recharge']
                        }
                    }
                ]
            }

        ]
    }
]
