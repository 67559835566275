// import { createRouter, createWebHashHistory, createWebHistory } from 'vue-router'
import { createRouter, createWebHistory } from 'vue-router'
import { useSettingsOutsideStore } from '@/store/modules/settings'
import { useKeepAliveOutsideStore } from '@/store/modules/keepAlive'
import { useUserOutsideStore } from '@/store/modules/user'
import { useRouteOutsideStore } from '@/store/modules/route'
import { useMenuOutsideStore } from '@/store/modules/menu'
import { useTabbarOutsideStore } from '@/store/modules/tabbar'

import '@/assets/styles/nprogress.scss'
import { useNProgress } from '@vueuse/integrations/useNProgress'
const { isLoading } = useNProgress()

// 固定路由
let constantRoutes = [
    {
        path: '/login',
        name: 'login',
        component: () => import('@/views/login.vue'),
        meta: {
            title: '登录',
            i18n: 'route.login'
        }
    },
    {
        path: '/media/show_article',
        name: 'media_show_article',
        component: () => import('@/views/media/show_article.vue'),
        meta: {
            title: '文章预览'
        }
    },
    {
        path: '/media/show_include_statistical_list',
        name: 'show_include_statistical_list',
        component: () => import('@/views/media/my_order/show_include_statistical_list.vue'),
        meta: {
            title: '软文媒体收录统计'
        }
    },
    {
        path: '/media/recruit_task_sign_up',
        name: 'media_recruit_task_sign_up',
        component: () => import('@/views/media/recruit_task_sign_up.vue'),
        meta: {
            title: '任务报名'
        }
    },
    {
        path: '/media/waf',
        name: 'media_withdraw_application_form',
        component: () => import('@/views/media/withdraw_application_form.vue'),
        meta: {
            title: '自助申请提现'
        }
    },
    {
        path: '/',
        component: () => import('@/layout/index.vue'),
        redirect: '/dashboard',
        children: [
            {
                path: 'dashboard',
                name: 'dashboard',
                component: () => import('@/views/index.vue'),
                meta: {
                    title: () => {
                        const settingsOutsideStore = useSettingsOutsideStore()
                        return settingsOutsideStore.dashboard.title
                    },
                    i18n: 'route.dashboard'
                }
            },
            {
                path: 'personal/setting',
                name: 'personalSetting',
                component: () => import('@/views/personal/setting.vue'),
                meta: {
                    title: '个人资料',
                    i18n: 'route.personal.setting',
                    cache: 'personalEditPassword',
                    breadcrumbNeste: [
                        { title: '个人资料', path: '/personal/setting' }
                    ]
                }
            },
            {
                path: 'personal/edit/password',
                name: 'personalEditPassword',
                component: () => import('@/views/personal/edit.password.vue'),
                meta: {
                    title: '修改密码',
                    i18n: 'route.personal.editpassword',
                    breadcrumbNeste: [
                        { title: '修改密码', path: '/personal/edit/password' }
                    ]
                }
            },
            {
                path: 'reload',
                name: 'reload',
                component: () => import('@/views/reload.vue')
            }
        ]
    }
]

// 免登录白名单
const noLoginWhitelist = [
    '/login',
    '/media/show_article',
    '/media/recruit_task_sign_up',
    '/media/show_include_statistical_list',
    '/media/waf'
]

// import MultilevelMenuExample from './modules/multilevel.menu.example'
// import BreadcrumbExample from './modules/breadcrumb.example'
// import KeepAliveExample from './modules/keep.alive.example'
// import menuIconExample from './modules/menu.icon.example'
// import menuBadgeExample from './modules/menu.badge.example'
// import tabExample from './modules/tab.example'
// import ComponentBasicExample from './modules/component.basic.example'
// import ComponentExtendExample from './modules/component.extend.example'
// import IconExample from './modules/icon.example'
// import FeatureExample from './modules/feature.example'
// import AnimateExample from './modules/animate.example'
// import ChartExample from './modules/chart.example'
// import PermissionExample from './modules/permission.example'
// import MockExample from './modules/mock.example'
// import JsxExample from './modules/jsx.example'
// import ExternalLinkExample from './modules/external.link.example'
// import PagesExample from './modules/pages.example'
// import VideosExample from './modules/videos.example'
// import EcologyExample from './modules/ecology.example'
// import CooperationExample from './modules/cooperation.example'
import Admin from './modules/admin/index'
import Media from './modules/media/index'

// 动态路由（异步路由、导航栏路由）
let asyncRoutes = [
    {
        meta: {
            title: '功能管理',
            i18n: 'route.demo',
            icon: 'ri-global-line'
            // auth: ['func']
            // icon: 'sidebar-default'
        },
        children: [
            ...Admin
            // MultilevelMenuExample,
            // BreadcrumbExample,
            // KeepAliveExample,
            // menuIconExample,
            // menuBadgeExample,
            // tabExample,
            // ComponentBasicExample,
            // ComponentExtendExample,
            // IconExample
            // FeatureExample,
            // AnimateExample,
            // ChartExample,
            // PermissionExample,
            // MockExample,
            // JsxExample,
            // ExternalLinkExample
        ]
    },
    {
        meta: {
            title: '媒介管理',
            i18n: 'route.page',
            icon: 'ri-pages-line'
        },
        children: [
            ...Media
        ]
    }
    // {
    //     meta: {
    //         title: '页面',
    //         i18n: 'route.page',
    //         icon: 'ri-pages-line'
    //     },
    //     children: [
    //         ...PagesExample
    //     ]
    // }
    // {
    //     meta: {
    //         title: '教程',
    //         icon: 'ri-movie-line'
    //     },
    //     children: [
    //         ...VideosExample
    //     ]
    // },
    // {
    //     meta: {
    //         title: '生态',
    //         i18n: 'route.ecology',
    //         icon: 'sidebar-ecology'
    //     },
    //     children: [
    //         ...EcologyExample
    //     ]
    // },
    // {
    //     meta: {
    //         title: '战略合作1213123',
    //         i18n: 'route.cooperation',
    //         icon: 'sidebar-cooperation'
    //     },
    //     children: [
    //         ...CooperationExample
    //     ]
    // }
]

const lastRoute = {
    path: '/:all(.*)*',
    name: 'notFound',
    component: () => import('@/views/[...all].vue'),
    meta: {
        title: '找不到页面'
    }
}

import { setupLayouts } from 'virtual:generated-layouts'
import generatedRoutes from 'virtual:generated-pages'

if (useSettingsOutsideStore().app.routeBaseOn === 'filesystem') {
    constantRoutes = generatedRoutes.filter(item => {
        return item.meta?.enabled !== false && item.meta?.constant === true
    })
    asyncRoutes = setupLayouts(generatedRoutes.filter(item => {
        return item.meta?.enabled !== false && item.meta?.constant !== true && item.meta?.layout !== false
    }))
}

const router = createRouter({
    history: createWebHistory(),
    routes: constantRoutes
})

// 不需要显示加载中的路由名称
let no_loading_router_name_list = [
    'media_show_article',
    'media_recruit_task_sign_up',
    'show_include_statistical_list',
    'media_withdraw_application_form'
]

router.beforeEach(async(to, from, next) => {
    const settingsOutsideStore = useSettingsOutsideStore()
    const userOutsideStore = useUserOutsideStore()
    const routeOutsideStore = useRouteOutsideStore()
    const menuOutsideStore = useMenuOutsideStore()
    const tabbarOutsideStore = useTabbarOutsideStore()

    console.log('to.name -->', to.name, no_loading_router_name_list.indexOf(to.name))
    if (no_loading_router_name_list.indexOf(to.name) == -1) {
        settingsOutsideStore.app.enableProgress = true && (isLoading.value = true)
    } else {
        settingsOutsideStore.app.enableProgress = false && (isLoading.value = false)
    }

    if (noLoginWhitelist.includes(to.path)) {
        next()
    }

    // 是否已登录
    if (userOutsideStore.isLogin) {
        // 是否已根据权限动态生成并挂载路由
        if (routeOutsideStore.isGenerate) {
            // 导航栏如果不是 single 模式，则需要根据 path 定位主导航的选中状态
            settingsOutsideStore.menu.menuMode !== 'single' && menuOutsideStore.setActived(to.path)
            if (to.name) {
                if (to.matched.length !== 0) {
                    // 如果已登录状态下，进入登录页会强制跳转到控制台页面
                    if (to.name == 'login') {
                        next({
                            name: 'dashboard',
                            replace: true
                        })
                    } else if (!settingsOutsideStore.dashboard.enable && to.name == 'dashboard') {
                        // 如果未开启控制台页面，则默认进入第一个固定标签栏或者侧边栏导航第一个模块
                        if (settingsOutsideStore.tabbar.enable && tabbarOutsideStore.list.some(v => v.isPin)) {
                            let fullPath
                            for (let i = 0; i < tabbarOutsideStore.list.length; i++) {
                                if (tabbarOutsideStore.list[i].isPin) {
                                    fullPath = tabbarOutsideStore.list[i].fullPath
                                    break
                                }
                            }
                            next({
                                path: fullPath,
                                replace: true
                            })
                        } else if (menuOutsideStore.sidebarMenus.length > 0) {
                            next({
                                path: menuOutsideStore.sidebarMenusFirstDeepestPath,
                                replace: true
                            })
                        } else {
                            next()
                        }
                    } else {
                        next()
                    }
                } else {
                    // 如果是通过 name 跳转，并且 name 对应的路由没有权限时，需要做这步处理，手动指向到 404 页面
                    next({
                        path: '/404'
                    })
                }
            } else {
                next()
            }
        } else {
            // 挂载动态路由的同时，根据当前帐号复原固定标签栏
            settingsOutsideStore.tabbar.enable && tabbarOutsideStore.recoveryStorage(userOutsideStore.account)
            switch (settingsOutsideStore.app.routeBaseOn) {
                case 'frontend':
                    await routeOutsideStore.generateRoutesAtFront(asyncRoutes)
                    break
                case 'backend':
                    await routeOutsideStore.generateRoutesAtBack()
                    break
                case 'filesystem':
                    await routeOutsideStore.generateRoutesAtFilesystem(asyncRoutes)
                    switch (settingsOutsideStore.menu.baseOn) {
                        case 'frontend':
                            await menuOutsideStore.generateMenusAtFront()
                            break
                        case 'backend':
                            await menuOutsideStore.generateMenusAtBack()
                            break
                    }
                    break
            }
            let removeRoutes = []
            routeOutsideStore.flatRoutes.forEach(route => {
                if (!/^(https?:|mailto:|tel:)/.test(route.path)) {
                    removeRoutes.push(router.addRoute(route))
                }
            })
            if (settingsOutsideStore.app.routeBaseOn === 'filesystem') {
                const otherRoutes = generatedRoutes.filter(item => item.meta?.constant !== true && item.meta?.layout === false)
                otherRoutes.length && removeRoutes.push(router.addRoute(...otherRoutes))
            } else {
                removeRoutes.push(router.addRoute(lastRoute))
            }
            // 记录路由数据，在登出时会使用到，不使用 router.removeRoute 是考虑配置的路由可能不一定有设置 name ，则通过调用 router.addRoute() 返回的回调进行删除
            routeOutsideStore.setCurrentRemoveRoutes(removeRoutes)
            next({ ...to, replace: true })
        }
    } else {
        if (!noLoginWhitelist.includes(to.path)) {
            next({
                name: 'login',
                query: {
                    redirect: to.fullPath
                }
            })
        } else {
            next()
        }
    }
})

router.afterEach((to, from) => {
    const settingsOutsideStore = useSettingsOutsideStore()
    const keepAliveOutsideStore = useKeepAliveOutsideStore()
    settingsOutsideStore.app.enableProgress && (isLoading.value = false)
    // 设置页面 title
    console.log('设置页面 title -->', to.name)
    if (no_loading_router_name_list.indexOf(to.name) == -1) {
        to.meta.title && settingsOutsideStore.setTitle(typeof to.meta.title === 'function' ? to.meta.title() : to.meta.title)
    } else {
        let title = typeof to.meta.title === 'function' ? to.meta.title() : to.meta.title
        document.title = title
    }

    // 判断当前页面是否开启缓存，如果开启，则将当前页面的 name 信息存入 keep-alive 全局状态
    if (to.meta.cache) {
        let componentName = to.matched[to.matched.length - 1].components.default.name
        if (componentName) {
            keepAliveOutsideStore.add(componentName)
        } else {
            console.warn('该页面组件未设置组件名，会导致缓存失效，请检查')
        }
    }
    // 判断离开页面是否开启缓存，如果开启，则根据缓存规则判断是否需要清空 keep-alive 全局状态里离开页面的 name 信息
    if (from.meta.cache) {
        let componentName = from.matched[from.matched.length - 1].components.default.name
        // 通过 meta.cache 判断针对哪些页面进行缓存
        switch (typeof from.meta.cache) {
            case 'string':
                if (from.meta.cache != to.name) {
                    keepAliveOutsideStore.remove(componentName)
                }
                break
            case 'object':
                if (!from.meta.cache.includes(to.name)) {
                    keepAliveOutsideStore.remove(componentName)
                }
                break
        }
        // 通过 meta.noCache 判断针对哪些页面不需要进行缓存
        if (from.meta.noCache) {
            switch (typeof from.meta.noCache) {
                case 'string':
                    if (from.meta.noCache == to.name) {
                        keepAliveOutsideStore.remove(componentName)
                    }
                    break
                case 'object':
                    if (from.meta.noCache.includes(to.name)) {
                        keepAliveOutsideStore.remove(componentName)
                    }
                    break
            }
        }
        // 如果进入的是 reload 页面，则也将离开页面的缓存清空
        if (to.name == 'reload') {
            keepAliveOutsideStore.remove(componentName)
        }
    }
})

export default router
