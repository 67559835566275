import { defineStore } from 'pinia'
import { piniaStore } from '@/store'
import storage from '@/util/storage'
import api from '@/api'

import { useTabbarStore } from './tabbar'
import { useRouteStore } from './route'
import { useMenuStore } from './menu'
import { ElMessageBox } from 'element-plus'

export const useUserStore = defineStore(
    // 唯一ID
    'user',
    {
        state: () => ({
            account: storage.local.get('account') || '',
            account_id: storage.local.get('account_id') || '',
            token: storage.local.get('token') || '',
            failure_time: storage.local.get('failure_time') || '',
            permissions: [],
            role_id: '',
            money: '',
            lastlogin_datetime: ''
        }),
        getters: {
            isLogin: state => {
                let retn = false
                if (state.token) {
                    if (new Date().getTime() < state.failure_time * 1000) {
                        retn = true
                    }
                }
                return retn
            },

            // 42 => 超级管理员  45 => 客户  52 => 售后
            // 判断当前角色是否是超级管理员
            isAdmin: state => {
                return state.role_id == 42
            },
            // 判断当前角色是否是客户
            isClient: state => {
                return state.role_id == 45
            },
            // 判断当前角色是否是售后
            isAfterSales: state => {
                return state.role_id == 52
            }
        },
        actions: {
            login(data) {
                return new Promise((resolve, reject) => {
                    api.post('/login', data).then(res => {
                        storage.local.set('account', data.account)
                        storage.local.set('account_id', res.data.id)
                        storage.local.set('token', res.data.token)
                        let failure_time = Math.ceil(new Date().getTime() / 1000) + 24 * 60 * 60
                        storage.local.set('failure_time', failure_time)
                        this.account = data.account
                        this.account_id = res.data.id
                        this.token = res.data.token
                        this.failure_time = failure_time
                        resolve()
                    }).catch(error => {
                        reject(error)
                    })
                })
            },
            sms_login(data) {
                return new Promise((resolve, reject) => {
                    api.post('/login/sms-login', data).then(res => {
                        storage.local.set('account', data.phone)
                        storage.local.set('account_id', res.data.id)
                        storage.local.set('token', res.data.token)
                        let failure_time = Math.ceil(new Date().getTime() / 1000) + 24 * 60 * 60
                        storage.local.set('failure_time', failure_time)
                        this.phone = data.phone
                        this.account_id = res.data.id
                        this.token = res.data.token
                        this.failure_time = failure_time
                        resolve()
                    }).catch(error => {
                        reject(error)
                    })
                })
            },
            logout() {
                return new Promise(resolve => {
                    const tabbarStore = useTabbarStore()
                    const routeStore = useRouteStore()
                    const menuStore = useMenuStore()
                    storage.local.remove('account')
                    storage.local.remove('token')
                    storage.local.remove('failure_time')
                    this.account = ''
                    this.token = ''
                    this.failure_time = ''
                    this.permissions = []
                    tabbarStore.clean()
                    routeStore.removeRoutes()
                    menuStore.setActived(0)
                    resolve()
                })
            },
            // 获取我的权限
            getPermissions() {
                return new Promise(resolve => {
                    // 通过 mock 获取权限
                    api.get('/user/info', {
                        // baseURL: '/mock/',
                        params: {
                            // account: this.account
                        }
                    }).then(res => {
                        this.permissions = res.data.access
                        this.role_id = res.data.role_id
                        this.money = res.data.money
                        this.lastlogin_datetime = res.data.lastlogin_datetime
                        resolve(res.data.access)
                    })
                })
            },
            editPassword(data) {
                return new Promise(resolve => {
                    api.post('member/edit/password', {
                        account: this.account,
                        password: data.password,
                        newpassword: data.newpassword
                    }, {
                        baseURL: '/mock/'
                    }).then(() => {
                        resolve()
                    })
                })
            },

            // 显示发稿人用户信息
            onShowUserInfo(user_id) {
                api.get('user', {
                    params: {
                        'id': user_id
                    }
                }).then(res => {

                    let current_user_data = res.data.data[0]
                    console.log('current_user_data -->', current_user_data)

                    let username = current_user_data['username']
                    let phone_number = current_user_data['phone_number']
                    let qq_id = current_user_data['qq_id'] ? current_user_data['qq_id'] : '未填写'
                    let wx_id = current_user_data['wx_id'] ? current_user_data['wx_id'] : '未填写'
                    ElMessageBox({
                        title: username,
                        message: h(
                            'p', null,
                            [
                                h('p', null, `手机号： ${phone_number}`),
                                h('p', null, `QQ： ${qq_id}`),
                                h('p', null, `微信号： ${wx_id}`)
                            ]
                        ),
                        showCancelButton: false,
                        showConfirmButton: false
                    })
                })

            }
        }
    }
)

export function useUserOutsideStore() {
    return useUserStore(piniaStore)
}
