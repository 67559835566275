const Layout = () => import('@/layout/index.vue')

export default [
    {
        path: '/media/personal',
        component: Layout,
        redirect: '/media/personal/info',
        name: 'media_personal',
        meta: {
            title: '个人中心',
            icon: 'el-icon-user-filled',
            auth: ['media.personal']
        },
        children: [
            {
                path: 'info',
                name: 'media_personal_info',
                redirect: '/media/personal/info',
                meta: {
                    title: '个人资料',
                    auth: ['media.personal_info']
                },
                children: [
                    {
                        path: '',
                        name: 'media_personal_info_list',
                        component: () => import('@/views/media/personal/info.vue'),
                        meta: {
                            title: '个人资料',
                            sidebar: false,
                            breadcrumb: false,
                            activeMenu: '/media/personal/info',
                            auth: ['media.personal_info.list']
                        }
                    }
                ]
            },
            {
                path: 'finance',
                name: 'media_personal_finance',
                redirect: '/media/personal/finance',
                meta: {
                    title: '财务报表',
                    auth: ['media.personal_finance']
                },
                children: [
                    {
                        path: '',
                        name: 'media_personal_finance_list',
                        component: () => import('@/views/media/personal/finance.vue'),
                        meta: {
                            title: '财务报表',
                            sidebar: false,
                            breadcrumb: false,
                            activeMenu: '/media/personal/finance',
                            auth: ['media.personal_finance.list']
                        }
                    }
                ]
            },
            {
                path: 'withdraw',
                name: 'media_personal_withdraw',
                redirect: '/media/personal/withdraw',
                meta: {
                    title: '提现',
                    auth: ['media.personal_withdraw']
                },
                children: [
                    {
                        path: '',
                        name: 'media_personal_withdraw_list',
                        component: () => import('@/views/media/personal/withdraw/list.vue'),
                        meta: {
                            title: '提现',
                            sidebar: false,
                            breadcrumb: false,
                            activeMenu: '/media/personal/withdraw',
                            auth: ['media.personal_withdraw.list']
                        }
                    },
                    {
                        path: 'create',
                        name: 'media_personal_withdraw_create',
                        component: () => import('@/views/media/personal/withdraw/create.vue'),
                        meta: {
                            title: '申请提现',
                            sidebar: false,
                            activeMenu: '/media/personal/withdraw',
                            // cache: true,
                            noCache: 'media_personal_withdraw_list'
                            // auth: ['media.personal_withdraw.create']
                        }
                    }
                    // {
                    //     path: 'detail/:id',
                    //     name: 'admin_role_edit',
                    //     component: () => import('@/views/admin/role/detail.vue'),
                    //     meta: {
                    //         title: '编辑角色',
                    //         sidebar: false,
                    //         activeMenu: '/admin/permisson/role',
                    //         cache: true,
                    //         noCache: 'admin_role_list',
                    //         auth: ['func.admin_role.edit']
                    //     }
                    // }
                ]
            }
        ]
    },
    {
        path: '/media/resource',
        component: Layout,
        redirect: '/media/resource/advertorial',
        name: 'media_resource',
        meta: {
            title: '我的资源',
            icon: 'el-icon-grid',
            auth: ['media.media_resource']
        },
        children: [
            {
                path: 'advertorial',
                name: 'media_advertorial',
                redirect: '/media/resource/advertorial',
                meta: {
                    title: '软文媒体',
                    // sidebar: false,
                    auth: ['media.media_advertorial']
                },
                children: [
                    {
                        path: '',
                        name: 'media_advertorial_list',
                        component: () => import('@/views/media/resource/advertorial/list.vue'),
                        meta: {
                            title: '软文媒体',
                            sidebar: false,
                            breadcrumb: false,
                            activeMenu: '/media/resource/advertorial',
                            cache: ['media_advertorial_edit'],
                            auth: ['media.media_advertorial.list']
                        }
                    },
                    {
                        path: 'detail',
                        name: 'media_advertorial_create',
                        component: () => import('@/views/media/resource/advertorial/detail.vue'),
                        meta: {
                            title: '新增媒体',
                            sidebar: false,
                            activeMenu: '/media/resource/advertorial',
                            cache: true,
                            noCache: 'media_advertorial_list',
                            auth: ['media.media_advertorial.create']
                        }
                    },
                    {
                        path: 'detail/:id',
                        name: 'media_advertorial_edit',
                        component: () => import('@/views/media/resource/advertorial/detail.vue'),
                        meta: {
                            title: '编辑媒体',
                            sidebar: false,
                            activeMenu: '/media/resource/advertorial',
                            cache: true,
                            noCache: 'media_advertorial_list',
                            auth: ['media.media_advertorial.edit']
                        }
                    }
                ]
            },

            {
                path: 'media_custom',
                name: 'media_custom',
                redirect: '/media/resource/media_custom',
                meta: {
                    title: '定制媒体',
                    // sidebar: false,
                    auth: ['media.media_custom']
                },
                children: [
                    {
                        path: '',
                        name: 'media_custom_list',
                        component: () => import('@/views/media/resource/custom/list.vue'),
                        meta: {
                            title: '定制媒体',
                            sidebar: false,
                            breadcrumb: false,
                            activeMenu: '/media/resource/media_custom',
                            // cache: ['media_advertorial_edit'],
                            auth: ['media.media_custom.list']
                        }
                    },
                    {
                        path: 'detail',
                        name: 'media_custom_create',
                        component: () => import('@/views/media/resource/custom/detail.vue'),
                        meta: {
                            title: '新增定制媒体',
                            sidebar: false,
                            activeMenu: '/media/resource/media_custom',
                            cache: true,
                            noCache: 'media_custom_list',
                            auth: ['media.media_custom.create']
                        }
                    },
                    {
                        path: 'detail/:id',
                        name: 'media_custom_edit',
                        component: () => import('@/views/media/resource/custom/detail.vue'),
                        meta: {
                            title: '编辑定制媒体',
                            sidebar: false,
                            activeMenu: '/media/resource/media_custom',
                            cache: true,
                            noCache: 'media_custom_list',
                            auth: ['media.media_custom.edit']
                        }
                    }
                ]
            },
            {
                path: 'media_weibo',
                name: 'media_weibo',
                redirect: '/media/resource/media_weibo',
                meta: {
                    title: '微博',
                    auth: ['media.media_weibo']
                },
                children: [
                    {
                        path: '',
                        name: 'media_weibo_list',
                        component: () => import('@/views/media/resource/digua/list.vue'),
                        meta: {
                            title: '微博',
                            sidebar: false,
                            breadcrumb: false,
                            activeMenu: '/media/resource/media_weibo',
                            auth: ['media.media_weibo.list'],
                            platform: 5
                        }
                    },
                    {
                        path: 'detail',
                        name: 'media_weibo_create',
                        component: () => import('@/views/media/resource/digua/detail.vue'),
                        meta: {
                            title: '添加',
                            sidebar: false,
                            activeMenu: '/media/resource/media_weibo',
                            cache: false,
                            platform: 5
                            // auth: ['media.media_weibo.create']
                        }
                    },
                    {
                        path: 'detail/:id',
                        name: 'media_weibo_edit',
                        component: () => import('@/views/media/resource/digua/detail.vue'),
                        meta: {
                            title: '编辑',
                            sidebar: false,
                            activeMenu: '/media/resource/media_weibo',
                            cache: false,
                            platform: 5
                            // auth: ['media.media_weibo.edit']
                        }
                    }
                ]
            },
            {
                path: 'media_digua',
                name: 'media_digua',
                redirect: '/media/resource/media_digua',
                meta: {
                    title: '地瓜',
                    // sidebar: false,
                    auth: ['media.media_digua']
                },
                children: [
                    {
                        path: '',
                        name: 'media_digua_list',
                        component: () => import('@/views/media/resource/digua/list.vue'),
                        meta: {
                            title: '地瓜',
                            sidebar: false,
                            breadcrumb: false,
                            activeMenu: '/media/resource/media_digua',
                            // cache: ['media_advertorial_edit'],
                            auth: ['media.media_digua.list'],
                            platform: 6
                        }
                    },
                    {
                        path: 'detail',
                        name: 'media_digua_create',
                        component: () => import('@/views/media/resource/digua/detail.vue'),
                        meta: {
                            title: '添加',
                            sidebar: false,
                            activeMenu: '/media/resource/media_digua',
                            cache: false,
                            platform: 6
                            // auth: ['media.media_weibo.create']
                        }
                    },
                    {
                        path: 'detail/:id',
                        name: 'media_digua_edit',
                        component: () => import('@/views/media/resource/digua/detail.vue'),
                        meta: {
                            title: '编辑',
                            sidebar: false,
                            activeMenu: '/media/resource/media_digua',
                            cache: false,
                            platform: 6
                            // auth: ['media.media_weibo.edit']
                        }
                    }
                ]
            },
            {
                path: 'media_douyin',
                name: 'media_douyin',
                redirect: '/media/resource/media_douyin',
                meta: {
                    title: '抖音',
                    auth: ['media.media_douyin']
                },
                children: [
                    {
                        path: '',
                        name: 'media_douyin_list',
                        component: () => import('@/views/media/resource/digua/list.vue'),
                        meta: {
                            title: '抖音',
                            sidebar: false,
                            breadcrumb: false,
                            activeMenu: '/media/resource/media_douyin',
                            auth: ['media.media_douyin.list'],
                            platform: 7
                        }
                    },
                    {
                        path: 'detail',
                        name: 'media_douyin_create',
                        component: () => import('@/views/media/resource/digua/detail.vue'),
                        meta: {
                            title: '添加',
                            sidebar: false,
                            activeMenu: '/media/resource/media_douyin',
                            cache: false,
                            platform: 7
                            // auth: ['media.media_weibo.create']
                        }
                    },
                    {
                        path: 'detail/:id',
                        name: 'media_douyin_edit',
                        component: () => import('@/views/media/resource/digua/detail.vue'),
                        meta: {
                            title: '编辑',
                            sidebar: false,
                            activeMenu: '/media/resource/media_douyin',
                            cache: false,
                            platform: 7
                            // auth: ['media.media_weibo.edit']
                        }
                    }
                ]
            },
            {
                path: 'media_dianping',
                name: 'media_dianping',
                redirect: '/media/resource/media_dianping',
                meta: {
                    title: '大众点评',
                    auth: ['media.media_dianping']
                },
                children: [
                    {
                        path: '',
                        name: 'media_dianping_list',
                        component: () => import('@/views/media/resource/digua/list.vue'),
                        meta: {
                            title: '大众点评',
                            sidebar: false,
                            breadcrumb: false,
                            activeMenu: '/media/resource/media_dianping',
                            auth: ['media.media_dianping.list'],
                            platform: 9
                        }
                    },
                    {
                        path: 'detail',
                        name: 'media_dianping_create',
                        component: () => import('@/views/media/resource/digua/detail.vue'),
                        meta: {
                            title: '添加',
                            sidebar: false,
                            activeMenu: '/media/resource/media_dianping',
                            cache: false,
                            platform: 9
                            // auth: ['media.media_weibo.create']
                        }
                    },
                    {
                        path: 'detail/:id',
                        name: 'media_dianping_edit',
                        component: () => import('@/views/media/resource/digua/detail.vue'),
                        meta: {
                            title: '编辑',
                            sidebar: false,
                            activeMenu: '/media/resource/media_dianping',
                            cache: false,
                            platform: 9
                            // auth: ['media.media_weibo.edit']
                        }
                    }
                ]
            },
            {
                path: 'media_guangguang',
                name: 'media_guangguang',
                redirect: '/media/resource/media_guangguang',
                meta: {
                    title: '淘宝逛逛',
                    auth: ['media.media_guangguang']
                },
                children: [
                    {
                        path: '',
                        name: 'media_guangguang_list',
                        component: () => import('@/views/media/resource/digua/list.vue'),
                        meta: {
                            title: '淘宝逛逛',
                            sidebar: false,
                            breadcrumb: false,
                            activeMenu: '/media/resource/media_guangguang',
                            auth: ['media.media_guangguang.list'],
                            platform: 10
                        }
                    },
                    {
                        path: 'detail',
                        name: 'media_guangguang_create',
                        component: () => import('@/views/media/resource/digua/detail.vue'),
                        meta: {
                            title: '添加',
                            sidebar: false,
                            activeMenu: '/media/resource/media_guangguang',
                            cache: false,
                            platform: 10
                            // auth: ['media.media_weibo.create']
                        }
                    },
                    {
                        path: 'detail/:id',
                        name: 'media_guangguang_edit',
                        component: () => import('@/views/media/resource/digua/detail.vue'),
                        meta: {
                            title: '编辑',
                            sidebar: false,
                            activeMenu: '/media/resource/media_guangguang',
                            cache: false,
                            platform: 10
                            // auth: ['media.media_weibo.edit']
                        }
                    }
                ]
            },
            {
                path: 'media_zhihu',
                name: 'media_zhihu',
                redirect: '/media/resource/media_zhihu',
                meta: {
                    title: '知乎',
                    auth: ['media.media_zhihu']
                },
                children: [
                    {
                        path: '',
                        name: 'media_zhihu_list',
                        component: () => import('@/views/media/resource/digua/list.vue'),
                        meta: {
                            title: '知乎',
                            sidebar: false,
                            breadcrumb: false,
                            activeMenu: '/media/resource/media_zhihu',
                            auth: ['media.media_zhihu.list'],
                            platform: 11
                        }
                    },
                    {
                        path: 'detail',
                        name: 'media_zhihu_create',
                        component: () => import('@/views/media/resource/digua/detail.vue'),
                        meta: {
                            title: '添加',
                            sidebar: false,
                            activeMenu: '/media/resource/media_zhihu',
                            cache: false,
                            platform: 11
                            // auth: ['media.media_weibo.create']
                        }
                    },
                    {
                        path: 'detail/:id',
                        name: 'media_zhihu_edit',
                        component: () => import('@/views/media/resource/digua/detail.vue'),
                        meta: {
                            title: '编辑',
                            sidebar: false,
                            activeMenu: '/media/resource/media_zhihu',
                            cache: false,
                            platform: 11
                            // auth: ['media.media_weibo.edit']
                        }
                    }
                ]
            },
            {
                path: 'media_dewu',
                name: 'media_dewu',
                redirect: '/media/resource/media_dewu',
                meta: {
                    title: '得物',
                    auth: ['media.media_dewu']
                },
                children: [
                    {
                        path: '',
                        name: 'media_dewu_list',
                        component: () => import('@/views/media/resource/digua/list.vue'),
                        meta: {
                            title: '知乎',
                            sidebar: false,
                            breadcrumb: false,
                            activeMenu: '/media/resource/media_dewu',
                            auth: ['media.media_dewu.list'],
                            platform: 18
                        }
                    },
                    {
                        path: 'detail',
                        name: 'media_dewu_create',
                        component: () => import('@/views/media/resource/digua/detail.vue'),
                        meta: {
                            title: '添加',
                            sidebar: false,
                            activeMenu: '/media/resource/media_dewu',
                            cache: false,
                            platform: 18
                            // auth: ['media.media_weibo.create']
                        }
                    },
                    {
                        path: 'detail/:id',
                        name: 'media_dewu_edit',
                        component: () => import('@/views/media/resource/digua/detail.vue'),
                        meta: {
                            title: '编辑',
                            sidebar: false,
                            activeMenu: '/media/resource/media_dewu',
                            cache: false,
                            platform: 18
                            // auth: ['media.media_weibo.edit']
                        }
                    }
                ]
            }
        ]
    },
    {
        path: '/media/order_manage',
        component: Layout,
        redirect: '/media/order_manage/my_order',
        name: 'media_order_manage',
        meta: {
            title: '订单管理',
            icon: 'el-icon-shopping-cart',
            auth: ['media.order_manage']
        },
        children: [
            {
                path: 'my_order',
                name: 'media_order_manage_my_order',
                redirect: '/media/order_manage/my_order',
                meta: {
                    title: '我的订单',
                    auth: ['media.my_order']
                },
                children: [

                    {
                        path: '',
                        name: 'media_my_order_list',
                        component: () => import('@/views/media/my_order/list.vue'),
                        meta: {
                            title: '我的订单',
                            sidebar: false,
                            breadcrumb: false,
                            activeMenu: '/media/order_manage/my_order',
                            // cache: true,
                            cache: [
                                // 'media_my_order_executive_order',
                                'media_my_order_put_link'
                            ],
                            // noCache: 'media_release_article_list',
                            auth: ['media.my_order.list']
                        }
                    },
                    {
                        path: 'executive_order',
                        name: 'media_my_order_executive_order',
                        component: () => import('@/views/media/my_order/executive_order.vue'),
                        meta: {
                            title: '执行订单',
                            sidebar: false,
                            breadcrumb: false,
                            activeMenu: '/media/order_manage/my_order',
                            // cache: true,
                            noCache: 'media_my_order_list',
                            auth: ['media.my_order.executive_order']
                        }
                    },
                    {
                        path: 'include_statistical',
                        name: 'include_statistical',
                        component: () => import('@/views/media/my_order/include_statistical_list.vue'),
                        meta: {
                            title: '收录统计',
                            sidebar: false,
                            breadcrumb: false,
                            activeMenu: '/media/order_manage/my_order'
                        }
                    }
                ]

            },
            {
                path: 'include_statistical',
                name: 'media_order_manage_include_statistical',
                redirect: '/media/order_manage/include_statistical',
                meta: {
                    title: '收录统计',
                    auth: ['media.include_statistical']
                },
                children: [

                    {
                        path: '',
                        name: 'media_include_statistical',
                        component: () => import('@/views/media/my_order/include_statistical_list.vue'),
                        meta: {
                            title: '收录统计',
                            sidebar: false,
                            breadcrumb: false,
                            activeMenu: '/media/order_manage/include_statistical',
                            auth: ['media.my_order.list']
                        }
                    }
                ]

            }

        ]
    },
    {
        path: '/media/tag',
        component: Layout,
        redirect: '/media/tag/media_tag',
        name: 'media_tag',
        meta: {
            title: '标签管理',
            sidebar: false,
            icon: 'sidebar-menu'
            // auth: ['func.media']
        },
        children: [
            {
                path: 'media_tag',
                name: 'media_media_tag',
                redirect: '/media/tag/media_tag',
                meta: {
                    title: '媒体标签管理'
                    // auth: ['func.media_advertorial']
                },
                children: [
                    {
                        path: '',
                        name: 'media_tag_list',
                        component: () => import('@/views/media/media_tag/list.vue'),
                        meta: {
                            title: '媒体标签管理',
                            sidebar: false,
                            breadcrumb: false,
                            activeMenu: '/media/tag/media_tag',
                            cache: ['media_tag_edit'],
                            enabled: false
                            // auth: ['func.media_advertorial.list']
                        }
                    },
                    {
                        path: 'detail',
                        name: 'media_tag_create',
                        component: () => import('@/views/media/media_tag/detail.vue'),
                        meta: {
                            title: '新增标签',
                            sidebar: false,
                            activeMenu: '/media/tag/media_tag',
                            cache: true,
                            noCache: 'media_tag_list'
                            // auth: ['func.media_advertorial.create']
                        }
                    },
                    {
                        path: 'detail/:id',
                        name: 'media_tag_edit',
                        component: () => import('@/views/media/media_tag/detail.vue'),
                        meta: {
                            title: '编辑标签',
                            sidebar: false,
                            activeMenu: '/media/tag/media_tag',
                            cache: true,
                            noCache: 'media_tag_list'
                            // auth: ['func.media_advertorial.edit']
                        }
                    }
                ]
            }
        ]
    },

    {
        path: '/media/recruit_blogger',
        component: Layout,
        name: 'media_recruit_blogger',
        redirect: '/media/recruit_blogger',
        meta: {
            title: '招募博主',
            icon: 'el-icon-aim',
            auth: ['media.recruit_blogger']
        },
        children: [
            {
                path: '',
                name: 'media_recruit_blogger_list',
                component: () => import('@/views/media/recruit_blogger/list.vue'),
                meta: {
                    title: '招募博主',
                    sidebar: false,
                    breadcrumb: false,
                    activeMenu: '/media/recruit_blogger',
                    cache: false,
                    // noCache: 'media_release_recruit_task_create',
                    auth: ['media.recruit_blogger.list']
                }

            }
        ]
    },
    {
        path: '/media/release_management',
        component: Layout,
        redirect: '/media/release_management/article',
        name: 'media_release_management',
        meta: {
            title: '稿件发布管理',
            icon: 'el-icon-promotion',
            auth: ['media.release_management']
        },
        children: [
            {
                path: 'article_create',
                name: 'media_release_management_article_create',
                redirect: '/media/release_management/article_create',
                meta: {
                    title: '新闻发布',
                    auth: ['media.media_release_management_article_create']
                },
                children: [

                    {
                        path: '',
                        name: 'media_release_article_create',
                        component: () => import('@/views/media/article/create.vue'),
                        meta: {
                            title: '新闻发布',
                            sidebar: false,
                            breadcrumb: false,
                            activeMenu: '/media/release_management/article_create',
                            // cache: true,
                            noCache: 'media_release_article_list',
                            auth: ['media.media_release_article_create.list']
                        }
                    }
                ]

            },
            {
                path: 'article',
                name: 'media_release_article',
                redirect: '/media/release_management/article',
                meta: {
                    title: '稿件列表',
                    auth: ['media.media_release_article']
                },
                children: [
                    {
                        path: '',
                        name: 'media_release_article_list',
                        component: () => import('@/views/media/article/list.vue'),
                        meta: {
                            title: '稿件列表',
                            sidebar: false,
                            breadcrumb: false,
                            activeMenu: '/media/release_management/article',
                            noCache: 'media_release_article_create',
                            auth: ['media.media_release_article.list']
                        }
                    }
                ]
            },
            {
                path: 'recruit_task',
                name: 'media_release_recruit_task',
                redirect: '/media/release_management/recruit_task',
                meta: {
                    title: '招募任务',
                    auth: ['media.media_release_recruit_task']
                },
                children: [
                    {
                        path: '',
                        name: 'media_release_recruit_task_list',
                        component: () => import('@/views/media/recruit_task/list.vue'),
                        meta: {
                            title: '招募任务',
                            sidebar: false,
                            breadcrumb: false,
                            activeMenu: '/media/release_management/recruit_task',
                            cache: false,
                            // noCache: 'media_release_recruit_task_create',
                            auth: ['media.media_release_recruit_task.list']
                        }

                    },
                    {
                        path: 'detail',
                        name: 'media_release_recruit_task_create',
                        component: () => import('@/views/media/recruit_task/detail.vue'),
                        meta: {
                            title: '发布任务',
                            sidebar: false,
                            activeMenu: '/media/release_management/recruit_task',
                            cache: false,
                            // noCache: 'media_release_recruit_task_list'
                            auth: ['media.media_release_recruit_task.create']
                        }
                    },
                    {
                        path: 'detail/:id',
                        name: 'media_release_recruit_task_edit',
                        component: () => import('@/views/media/recruit_task/detail.vue'),
                        meta: {
                            title: '编辑任务',
                            sidebar: false,
                            activeMenu: '/media/release_management/recruit_task',
                            cache: false,
                            // noCache: 'media_release_recruit_task_list'
                            auth: ['media.media_release_recruit_task.edit']
                        }
                    },

                    {
                        path: 'recruit_task_sign_up',
                        name: 'media_release_recruit_task_sign_up',
                        component: () => import('@/views/media/recruit_task/recruit_task_sign_up_list.vue'),
                        meta: {
                            title: '报名列表',
                            sidebar: false,
                            breadcrumb: false,
                            activeMenu: '/media/release_management/recruit_task',
                            cache: false,
                            // noCache: 'media_release_recruit_task_create',
                            auth: ['media.media_release_recruit_task.list']
                        }

                    }
                ]
            }

        ]
    },
    {
        path: '/media/withdraw_application_form',
        component: Layout,
        name: 'media_withdraw_application_form',
        redirect: '/media/withdraw_application_form',
        meta: {
            title: '自助提现申请',
            icon: 'el-icon-aim',
            auth: ['media.withdraw_application_form']
        },
        children: [
            {
                path: '',
                name: 'media_withdraw_application_form_list',
                component: () => import('@/views/media/withdraw_application_form/list.vue'),
                meta: {
                    title: '自助提现申请',
                    sidebar: false,
                    breadcrumb: false,
                    activeMenu: '/media/withdraw_application_form',
                    cache: false,
                    // noCache: 'media_release_recruit_task_create',
                    auth: ['media.withdraw_application_form.list']
                }

            }
        ]
    }

]
